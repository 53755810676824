(<template>
  <section class="withdraw-rules">
    <h3 class="withdraw-rules__title">{{ title }}</h3>
    <p v-for="item in withdrawRules"
       :key="item.text"
       class="withdraw-rules__item"
       v-html="item.text">
    </p>
  </section>
</template>)

<script>
  import getProductConfig from '@/modules/productConfig';

  export default {
    computed: {
      supportInterpreterEmail() {
        return getProductConfig(this.$gettext, 'salita', 'domainData', 'subSupportEmail');
      },
      title() {
        return this.$gettext('Information about changes in routines on the platform:');
      },
      emailLinkText() {
        const template = this.$gettext('Please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a> to withdraw from this assignment.');
        return this.$gettextInterpolate(template, {email: this.supportInterpreterEmail});
      },
      withdrawRules() {
        const template = this.$gettext('2. If you still do not have the opportunity to carry out an already confirmed assignment and there are 48 hours until the assignment starts, you must in future notify our customer center manually at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a>');
        return [
          {
            text: this.$gettext('1. Assignments that you have accepted are <b>binding</b>.')
          },
          {
            text: this.$gettextInterpolate(template, {email: this.supportInterpreterEmail})
          }
        ];
      }
    },
  };
</script>

<style scoped>
.withdraw-rules {
  margin-bottom: 14px;
}

.withdraw-rules__title {
  font-weight: normal;
  font-size: 18px;
}

.withdraw-rules__item {
  margin-top: 10px;
}
</style>
