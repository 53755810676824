(<template>
  <banner class="feedback-banner">
    <div v-for="(line, index) in bannerLines"
         :key="index"
         class="feedback-banner__line"
         v-html="line"></div>
  </banner>
</template>)

<script>
  import {mapState} from 'vuex';
  import Banner from '@/components/shared_components/banners/Banner';

  export default {
    components: {
      banner: Banner
    },
    computed: {
      ...mapState('s_DashboardStore', {feedbackJobs: ({feedbackJobs}) => feedbackJobs || []}),
      bannerLines() {
        const template = this.$gettext('We need your feedback before we can invoice %{customerName} for the assignment at %{address} on %{date} <a class="sk-btn sk-btn--default" href="%{link}">View assignment #%{assignmentId}</a>');
        return this.feedbackJobs.map((feedbackJob) => {
          const linkHref = this.$router.resolve(this.$makeRoute({name: 'ServerOneAssignment', params: {id: feedbackJob.id}})).href;
          return this.$gettextInterpolate(template, {
            customerName: feedbackJob.owner,
            address: `${feedbackJob.contactAttributes.address.line1}, ${feedbackJob.contactAttributes.address.city}`,
            date: this.$moment(feedbackJob.startTime).format('DD/MM/YYYY'),
            link: linkHref,
            assignmentId: feedbackJob.id
          });
        });
      }
    }
  };
</script>

<style>
  .feedback-banner .sk-btn {
    width: auto;
    min-width: 190px;
    margin-left: 20px;
    padding: 0 15px;
  }

  @media (max-width: 500px) {
    .feedback-banner .sk-btn {
      margin-top: 5px;
      margin-left: 0;
    }
  }
</style>

<style scoped>
  .feedback-banner {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .feedback-banner__line {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 5px 0;
  }

  @media (max-width: 500px) {
    .feedback-banner__line {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
</style>
