(<template>
  <banner :progress-active="bannerProgress"
          class="service-banner">
    <div class="service-banner__line">
      <span>{{ $gettext('Are you interested in receiving new job offers?') }}</span>
      <div class="service-banner__btns">
        <button class="sk-btn sk-btn--default"
                @click="changeLanguageAssistantStatus('interested')">{{ $gettext('I\'m interested') }}</button>
        <button class="sk-btn sk-btn--default"
                @click="changeLanguageAssistantStatus('not_interested')">{{ $gettext('I\'m not interested') }}</button>
      </div>
    </div>
  </banner>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Banner from '@/components/shared_components/banners/Banner';

  export default {
    components: {
      banner: Banner
    },
    data() {
      return {
        bannerProgress: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
    },
    methods: {
      ...mapActions('EditProfileStore', ['updateUserProfile']),
      changeLanguageAssistantStatus(value) {
        const form = new FormData();
        form.append('interpreter[language_assistant]', value);

        this.bannerProgress = true;
        this.updateUserProfile({id: this.userUid, form}).then(() => {
          this.$store.commit('EditProfileStore/setLanguageAssistant', value);
          this.bannerProgress = false;
        });
      },
    }
  };
</script>

<style>
  .service-banner .sk-btn {
    width: auto;
    min-width: 190px;
    margin-left: 20px;
    padding: 0 15px;
  }

  @media (max-width: 650px) {
    .service-banner .sk-btn {
      margin-top: 5px;
      margin-left: 0;
    }
  }
</style>

<style scoped>
  .service-banner {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .service-banner__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0;
  }

  .service-banner__btns {
    display: flex;
  }

  @media (max-width: 650px) {
    .service-banner__btns {
      display: block;
    }
  }
</style>
