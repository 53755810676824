(<template>
  <section :class="{'in-progress': progressActive}"
           class="stand-by is-progress-bar">
    <h3 class="stand-by__title">{{ title }}</h3>
    <p class="stand-by__description"
       v-html="description"></p>
    <add-stand-by-event />
    <button class="sk-btn sk-btn--white stand-by__btn"
            @click="submitData()">{{ $gettext('Save') }}</button>
    <div class="stand-by__events">
      <div v-for="event in composedStandByEvents"
           :key="event.id">
        <event :event="event"
               :hide-title="true"
               class="stand-by__event"
               @deleteevent="removeEvent">
          <div slot="eventIcon"
               :class="[`stand-by__event-icon--${event.sessionType || 'on_site'}`]"
               class="stand-by__event-icon"></div>
          <div v-if="event.sessionType === 'on_site'"
               slot="eventExtraText"
               class="stand-by__event-text">{{ getTravelInfo(event) }}</div>
        </event>
      </div>
      <div v-if="standByEvents.length > 3"
           :class="{'is-opened': showMoreDates}"
           class="stand-by__link-wrapper">
        <button class="sk-link sk-link--default stand-by__link"
                @click="clickMoreDates">{{ moreDatesText }}</button>
        <span class="sk-select__arrow"></span>
      </div>
    </div>
  </section>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import AddPrivateEventPrototype from '@/prototypes/AddPrivateEventPrototype';
  import Event from '@/components/preferences/private_events/OneEvent';
  import AddStandByEvent from '@/components/dashboard_components/stand-by_events/AddStandByEvent';

  export default {
    components: {
      'add-stand-by-event': AddStandByEvent,
      event: Event
    },
    extends: AddPrivateEventPrototype,
    data() {
      return {
        progressActive: false,
        showMoreDates: false
      };
    },
    computed: {
      ...mapGetters('TTPrivateEventsStore', [
        'standByEvents'
      ]),
      ...mapState('TTPrivateEventsStore', {
        privateEvents: ({privateEvents}) => privateEvents || [],
      }),
      title() {
        return this.$gettext('StandBy interpreter');
      },
      moreDatesText() {
        return this.showMoreDates ? this.$gettext('Hide more dates') : this.$gettext('View more dates');
      },
      description() {
        return this.$gettext('If you make yourself available, you will automatically be assigned a task. <a href="https://support.salita.no/article/758-standby-tolk/" target="_blank" class="sk-link sk-link--default">Read more about this service</a>');
      },
      composedStandByEvents() {
        return this.showMoreDates ? this.standByEvents : this.standByEvents.slice(0, 3);
      }
    },
    methods: {
      ...mapActions('TTPrivateEventsStore', [
        'removePrivateEvent'
      ]),
      setProgress(value) {
        this.progressActive = value;
      },
      getTravelInfo(event = {}) {
        const template = this.$gettext('%{travelMethod}, up to %{distance} km');
        const travelMethod = helpers.getJobInfo.getTravelMethodType(this, event.travelType);
        const distance = event.distance || '';

        return this.$gettextInterpolate(template, {travelMethod, distance});
      },
      clickMoreDates() {
        this.showMoreDates = !this.showMoreDates;
      },
      removeEvent(eventId) {
        this.setProgress(true);
        this.removePrivateEvent(eventId).then(() => {
          this.setProgress(false);
        });
      },
      successHandling() {
        this.$store.commit('InfoModalStore/setInfoModal', {
          text: this.$gettext('Success!')
        });
      }
    },
    beforeMount() {
      this.setInitPrivateEvent({
        eventType: 3,
        title: this.$gettext('StandBy')
      });
    }
  };
</script>

<style>
  .stand-by .add-event__datepicker,
  .stand-by .add-event__timepicker {
    width: 33.3%;
    margin: 0;
    padding: 0 7.5px;
  }

  .stand-by .add-event__dates {
    width: calc(100% + 15px);
    margin: 0 -7.5px;
  }
</style>

<style scoped>
  .stand-by__title {
    margin-bottom: 10px;
    font-weight: normal;
  }

  .stand-by__fields-wrapper {
    display: flex;
    width: calc(100% + 15px);
    margin: 15px -7.5px 0;
  }

  .stand-by__field {
    flex-grow: 1;
    flex-basis: 50%;
    margin: 0 7.5px;
  }

  .stand-by__btn {
    width: 100%;
    margin-top: 15px;
    padding: 0 20px;
  }

  .stand-by__link-wrapper {
    position: relative;
    display: flex;
    height: 35px;
    border-bottom: 1px solid #e9ebef;
  }

  .stand-by__link {
    width: 100%;
    text-align: left;
    text-decoration: none;
  }

  .stand-by__event:first-child {
    border-top: 1px solid #e9ebef;
  }

  .stand-by__events {
    margin-top: 15px;
  }

  .stand-by__event {
    flex-wrap: nowrap;
    padding: 10px 0;
    border-bottom: 1px solid #e9ebef;
  }

  .stand-by__event-text {
    font-size: 12px;
  }

  .stand-by__event-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #fff;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .stand-by__event-icon--remote {
    background-image: url(~@assets/imgs/assignment_types/posting/phone.svg);
    background-image: var(--image-assignment-type-phone-icon);
  }

  .stand-by__event-icon--on_site {
    background-image: url(~@assets/imgs/assignment_types/posting/inperson.svg);
    background-image: var(--image-assignment-type-in-person-icon);
  }
</style>
